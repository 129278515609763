@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'TT-Commons';
  src: url('./fonts/TT-Commons-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TT-Commons';
  src: url('./fonts/TT-Commons-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: white;
  font-family: 'TT-Commons', sans-serif !important;
}

header {
  font-family: 'Gilroy', sans-serif !important;
}

.gilroy {
  font-family: 'Gilroy', sans-serif !important;
}

.rm-focus:focus {
  outline: none !important;
  border: none !important;
}

.alternateBackground {
  background-color: #F5F6FA;
}

.borderBottomGreen {
  margin-top: 25px;
  background: linear-gradient(176deg, #6CEDBE 0%, #1DBB86 100%);
  width: 100%;
  height: 6px;
  border-radius: 0 0 5px 5px;
}

.mainButton {
  background-color: #212121;
  border-radius: 6px;
  padding: 12px 35px;
}

.heroLineHeight {
  line-height: 42px !important;
}

@media (min-width: 768px) {
  .heroLineHeight {
    line-height: 60px !important;
  }
}

input:focus, textarea:focus {
  outline: none;
}

.customVhHeight {
  min-height: calc(100vh - 48px);
}